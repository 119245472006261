import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";
import UserService from "../../users/UserService";

const userService = new UserService();

export default class SyncShipmentService{


    getRecordByShipmentId(id) {

        const apiKey = userService.getApiKey();

        let queryString = "";

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/shipments/queue/${id}` + queryString, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

}