import {Card, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {formatDateTime} from "../utils/utils";


export default function SyncShipmentDetails(props){

    const {t} = useTranslation();
    const item = props.item;

    return <Card style={{padding: "5%", width: 500, margin: "auto"}} elevation={3}>
        <Typography>
            {t("external_transmission_towards") + " " + item?.carrier}
        </Typography>
        <Typography color={"red"}>
            {t("error") + ": " + item?.errorMessage}
        </Typography>
        <Typography>
            {t("last_try_date_time") + ": " + formatDateTime(item?.lastTryDateTime)}
        </Typography>
    </Card>



}